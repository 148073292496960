<template>
  <div class="container">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <div class="center">
      <b-button id="processing1" class="processing_first" @click="farmaOrders(false)">Recebidas</b-button>
      <b-button id="processing2" v-if="show_OCP" class="processing" @click="farmaOrdersOCP">OCP</b-button>
      <b-button id="processing4" class="processing" @click="farmasPurchaseLab">Pedidos a Labóratórios</b-button>
      <b-button id="processing3" class="processing" @click="farmaOrdersConf">Histórico</b-button>
    </div>
    <br />
    <span>
      <div class="col">
        <div class="row">
          <div class="column">
            <div class="box box1">
              <img :src="img" />
            </div>
          </div>
          <div class="column">
            <form>
              <div class="form-group1">
                <label for="sku">CNP</label>
                <input type="text" class="form-control" id="sku" v-model="skuInfo" disabled />
              </div>
              <div class="form-group1">
                <label for="ean">EAN</label>
                <input type="text" class="form-control" id="ean" v-model="eanInfo" disabled />
              </div>
              <div class="form-group1">
                <label for="productName">Nome</label>
                <input type="text" class="form-control" id="productName" disabled v-model="nameInfo" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <form v-on:submit.prevent="getProduct()">
        <div id="searchEan" class="form-group row">
          <label for="productEan" id="lblProductEan" class="col-sm col-form-label"><b>Cód. Produto:</b></label>
          <div class="col-sm">
            <input type="text" class="form-control" id="productEan" autofocus v-model="ean" />
          </div>
          <div class="col-sm">
            <button class="btn btn-primary">Adicionar</button>
          </div>
        </div>
      </form>
    </span>
    <span v-if="this.farmasLabs == false">
      <h1 class="center" v-if="orders == ''">{{ message }}</h1>
      <span v-else>
        <table class="table">
          <tr>
            <th>Data pedido</th>
            <th v-if="!show">Data Confirmação</th>
            <th>Nome</th>
            <th>CNP</th>
            <th>Preço</th>
            <th>Pedidos</th>
            <th>Confirmados</th>
            <th>Cancelar</th>
          </tr>
          <tr
            v-for="order in orders"
            :key="order.id"
            :style="order.sku == skuInfo ? { 'background-color': '#ff9393' } : { 'background-color': 'white' }"
          >
            <td class="align-middle custom-td">
              {{ formatDate(order.date_request) }}
            </td>
            <td class="align-middle custom-td" v-if="!show">
              {{ order.dataF }}
            </td>
            <td class="align-middle custom-td">{{ order.name }}</td>
            <td class="align-middle custom-td">
              <a id="openArticle" v-b-modal.modal-3 @click="passProductData(order)">{{ order.sku }}</a>
            </td>
            <td class="align-middle custom-td">{{ order.price_request }} €</td>
            <td class="align-middle custom-td">{{ order.qty_request }}</td>
            <td class="align-middle custom-td">
              <span v-if="order.status == 'request'">
                <div class="row">
                  <div class="col">
                    <input
                      type="number"
                      class="form-control"
                      min="1"
                      :max="order.qty_request"
                      v-model="order.qty_confirmed"
                      :style="
                        order.qty_confirmed < order.qty_request && order.qty_confirmed != 0
                          ? { color: 'red' }
                          : { color: 'black' }
                      "
                      :disabled="checkedOrders.includes(order.id)"
                    />
                  </div>
                </div>
              </span>
              <span v-else-if="order.status == 'confirmed'">
                {{ order.qty_confirmed }}
              </span>
            </td>
            <td class="align-middle custom-td" v-if="order.status == 'request'">
              <input type="checkbox" class="large-checkbox" :value="order.id" v-model="checkedOrders" />
            </td>
            <td class="align-middle custom-td" v-if="order.status == 'confirmed'">
              <button
                class="btn btn-primary"
                id="openModal"
                v-b-modal.modal-extern
                @click="modalUpdate(order.id, order.name, order.sku, order.qty_request, order.qty_confirmed)"
              >
                Update
              </button>
            </td>
          </tr>
        </table>
      </span>
      <span v-if="show == true">
        <div class="center">
          <b-button class="completed" @click="confirmFinal()">Finalizar</b-button>
        </div>
      </span>
    </span>
    <span v-else-if="this.farmasLabs == true">
      <div v-for="brand of farmasLab" :key="brand.brand">
        <div class="card">
          <h5 class="card-header">
            <b>{{ brand.lab }}</b>
          </h5>
          <div class="card-body">
            <table class="table">
              <tr>
                <th style="width: 11%">CNP</th>
                <th>Nome</th>
                <th style="width: 11%">Data</th>
                <th>Qtd. Pedida</th>
                <th>Qtd. para Farmas</th>
                <th>Qtd. para Idivia</th>
                <th>Confirmados</th>
              </tr>
              <tr
                v-for="order in brand.orders"
                :key="order.id"
                :style="order.sku == skuInfo ? { 'background-color': '#ff9393' } : { 'background-color': 'white' }"
              >
                <td class="align-middle">
                  <a id="openArticle" v-b-modal.modal-3 @click="passProductData(order)">{{ order.sku }}</a>
                </td>
                <td class="align-middle">{{ order.name }}</td>
                <td class="align-middle">
                  {{ splitDate2(order.purchase_date) }}
                </td>
                <td class="align-middle">{{ order.qt_order }}</td>
                <td class="align-middle">
                  <span style="text-decoration: underline">Alvim:</span>
                  {{ order.to_alvim }}
                  <br />
                  <span style="text-decoration: underline"> Gomes: </span>{{ order.to_gomes }}

                  <br />
                  <span style="text-decoration: underline"> Martins:</span>
                  {{ order.to_martins }}

                  <br />
                  <span style="text-decoration: underline"> Perelhal:</span>
                  {{ order.to_perelhal }}

                  <br />
                </td>
                <td class="align-middle">
                  <b>{{ order.to_idivia }}</b>
                </td>
                <td class="align-middle">
                  <input
                    type="number"
                    min="1"
                    :max="order.to_idivia"
                    class="form-control"
                    v-model="order.qty_confirmed"
                    :style="order.qty_confirmed > order.to_idivia ? { color: 'red' } : { color: 'black' }"
                  />
                </td>
              </tr>
            </table>
            <div>
              <button class="btn btn-primary float-right" @click="confirmLabsRequest(brand.orders, brand.lab)">
                Confirmar a Idivia
              </button>
            </div>
          </div>
        </div>
      </div>
    </span>

    <!-- MODAL PARA MOSTRAR O PRODUTO -->
    <b-modal ref="modal_product" id="modal-3" :title="'Produto ' + this.skuProduct" size="lg" hide-footer>
      <div class="card" id="cardProduct">
        <b-container>
          <b-row align-v="center"
            ><b-col class="left">
              <!-- IMAGEM DO PRODUTO -->
              <img :src="imgProduct" />
            </b-col>
            <b-col>
              <p><span id="subtitle">STOCK: </span> {{ stockProduct }}</p>
              <p><span id="subtitle">EAN:</span> {{ eanProduct }}</p>
              <p><span id="subtitle">SKU:</span> {{ skuProduct }}</p>
              <p><span id="subtitle">HS Code:</span> {{ hsProduct }}</p>
              <p><span id="subtitle">Notas Arm.:</span> {{ warehouseProduct }}</p>
              <p><span id="subtitle">Notas:</span> {{ notesProduct }}</p>
              <p><span id="subtitle">Peso:</span> {{ weightProduct }} (g)</p>
            </b-col></b-row
          >
        </b-container>
      </div>
    </b-modal>
    <b-modal id="modal-extern" title="Atualizar Transação!" ref="modal_externSupplier" hide-footer>
      <form v-on:submit.prevent="UpdateTransaction()">
        <div class="form-group">
          <label for="sku">Nome</label>
          <input type="text" class="form-control" id="modal_name" :placeholder="modal_name" disabled />
        </div>
        <div class="form-group">
          <label for="sku">SKU</label>
          <input type="text" class="form-control" id="modal_sku" :placeholder="modal_sku" disabled />
        </div>
        <div class="form-group"></div>
        <div class="form-group">
          <label for="modal_qty_order">Quantidade Pedida</label>
          <input type="number" class="form-control" id="modal_qty_order" :placeholder="modal_qty_order" disabled />
        </div>
        <div class="form-group">
          <label for="modal_qty_confirmed">Quantidade Confirmada</label>
          <input
            type="number"
            class="form-control"
            id="modal_qty_confirmed"
            :placeholder="modal_qty_confirmed"
            v-model="modal_qty_confirmed"
          />
        </div>
        <button class="btn btn-primary" type="submit">Atualizar</button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      loading: false,
      orders: [],
      message: '',
      orders_id: [],
      eans: [],
      ean: '',
      products: [],
      productInfo: [],
      skuInfo: '',
      eanInfo: '',
      nameInfo: '',
      img: 'https://www.svgrepo.com/show/97910/image.svg',
      show: false,
      eanProduct: '',
      skuProduct: '',
      hsProduct: '',
      warehouseProduct: '',
      notesProduct: '',
      weightProduct: '',
      imgProduct: '',
      idProduct: '',
      stockProduct: '',
      modal_id: '',
      modal_name: '',
      modal_sku: '',
      modal_qty_order: '',
      modal_qty_confirmed: '',
      user: '',
      show_OCP: false,
      OCP: false,
      miss_prod: [],
      canceled: false,
      done: false,
      suppliers: [],
      farmasLab: [],
      farmasLabs: false,
      lastButtonClicked: '',
      checkedOrders: [],
    };
  },
  methods: {
    formatDate(dateString) {
      const pad = (num) => (num < 10 ? '0' + num : num);

      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = pad(date.getMonth() + 1); // Months are 0-based
      const day = pad(date.getDate());
      const hours = pad(date.getHours());
      const minutes = pad(date.getMinutes());
      const seconds = pad(date.getSeconds());

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    splitDate2(date) {
      let split = date.split('T');
      return split[0];
    },
    async farmaOrders(first) {
      this.lastButtonClicked = 'farmaOrders';
      if (!first) {
        document.getElementById('processing1').style.backgroundColor = 'blue';
        if (this.show_OCP) document.getElementById('processing2').style.backgroundColor = 'gray';
        document.getElementById('processing4').style.backgroundColor = 'gray';
        document.getElementById('processing3').style.backgroundColor = 'gray';
        this.farmasLabs = false;
      }
      try {
        this.OCP = false;
        await this.$store.dispatch('getFarmaOrders', { supplier: undefined });
        this.orders = this.getFarmaOrders;
        console.log(this.orders);
        if (this.orders.length == 0) {
          this.message = 'Não existem novos pedidos';
          this.show = false;
        } else {
          this.show = true;
          for (let index = 0; index < this.orders.length; index++) {
            const element = this.orders[index];
            const filterProduct1 = this.products.filter((product) => product.sku.includes(element.sku));
            console.log(filterProduct1[0]);
            this.orders[index].name = filterProduct1[0].name;
          }
        }
      } catch (err) {
        alert(err);
      }
    },
    async farmaOrdersOCP() {
      this.lastButtonClicked = 'farmaOrdersOCP';
      document.getElementById('processing1').style.backgroundColor = 'gray';
      if (this.show_OCP) document.getElementById('processing2').style.backgroundColor = 'blue';
      document.getElementById('processing4').style.backgroundColor = 'gray';
      document.getElementById('processing3').style.backgroundColor = 'gray';
      this.farmasLabs = false;
      try {
        this.OCP = true;
        await this.$store.dispatch('getFarmaOrders', {
          supplier: 'OCP Portugal',
        });
        this.orders = this.getFarmaOrders;
        console.log(this.orders);
        if (this.orders.length == 0) {
          this.message = 'Não existem novos pedidos OCP';
          this.show = false;
        } else {
          this.show = true;

          for (let index = 0; index < this.orders.length; index++) {
            const element = this.orders[index];
            const filterProduct1 = this.products.filter((product) => product.sku.includes(element.sku));
            console.log(filterProduct1[0]);
            this.orders[index].name = filterProduct1[0].name;
          }
        }
      } catch (err) {
        alert(err);
      }
    },
    async farmaOrdersConf() {
      document.getElementById('processing1').style.backgroundColor = 'gray';
      if (this.show_OCP) document.getElementById('processing2').style.backgroundColor = 'gray';
      document.getElementById('processing4').style.backgroundColor = 'gray';
      document.getElementById('processing3').style.backgroundColor = 'blue';
      this.farmasLabs = false;
      try {
        this.show = false;
        this.OCP = false;
        await this.$store.dispatch('getFarmaOrdersConf');
        this.orders = this.getFarmaOrdersConf;
        console.log(this.orders);
        if (this.orders.length == 0) {
          this.message = 'Não existem pedidos confirmados';
        } else {
          for (let index = 0; index < this.orders.length; index++) {
            const element = this.orders[index];
            const filterProduct1 = this.products.filter((product) => product.sku.includes(element.sku));
            console.log(filterProduct1[0]);
            this.orders[index].name = filterProduct1[0].name;
            let newDate1 = new Date(this.orders[index].date_request);
            let year1 = newDate1.getFullYear();
            let month1 = ('0' + (newDate1.getMonth() + 1)).slice(-2);
            let day1 = ('0' + newDate1.getDate()).slice(-2);
            this.orders[index].dataF = `${year1}-${month1}-${day1}`;
            console.log(this.orders[index].dataF);
          }
        }
      } catch (err) {
        alert(err);
      }
    },
    async getProduct() {
      let ean = this.products.filter((product) => product.ean.includes(this.ean));
      /* CASO O VALOR INSERIDO NÃO SEJA DETECTADO VERIFICAMOS SE FOI UM SKU INTRODUZIDO */
      if (ean.length == 0) {
        ean = this.products.filter((product) => product.sku.includes(this.ean));
      }
      if (ean.length != 0) {
        this.productInfo = ean;
        this.nameInfo = this.productInfo[0].name;
        this.eanInfo = this.productInfo[0].ean;
        this.skuInfo = this.productInfo[0].sku;
        this.img = this.productInfo[0].img;
        let index = this.orders.findIndex((prod) => this.productInfo.sku.includes(prod.sku));
        console.log('INDEX: ', index);

        this.orders[index].qty_confirmed++;
      } else {
        /* SWEET - ALERT; */
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: 'Não encontramos o Produto :(',
          showConfirmButton: false,
        });
      }
      this.ean = '';
    },
    async confirmFinal() {
      console.log('ENTREI');
      console.log(this.orders);
      for (let index = 0; index < this.orders.length; index++) {
        const element = this.orders[index];
        console.log(element, element.qty_confirmed, element.qty_request);
        if (Number(element.qty_confirmed) < Number(element.qty_request)) {
          if (this.done == false && this.canceled == false) {
            await this.$swal({
              title: 'Existem produtos cuja quantidade confirmada é menor que a pedida deseja confirmar?',
              text: 'Todos os pedidos selecionados serão cancelados!',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sim!',
              cancelButtonText: 'Cancelar',
            }).then((result) => {
              if (result.isConfirmed) {
                this.finalizar();
                this.$swal('Completado', 'Produtos confirmados!', 'success');
                this.done = true;
              } else if (!result.isConfirmed) {
                this.canceled = true;
              }
            });
          }
        }
      }
      if (this.done == false && this.canceled == false) {
        this.finalizar();
      }
      if (this.checkedOrders.length > 0) {
        this.cancelTransactions();
      }
      this.done = false;
      this.canceled = false;
    },
    async finalizar() {
      let supplier1 = JSON.parse(localStorage.getItem('user')).username.toUpperCase();
      if (this.OCP) {
        supplier1 = 'FARMACIA ALVIM';
      }
      let final_array = [];
      for (let index = 0; index < this.orders.length; index++) {
        const element = this.orders[index];
        if ((element.qty_confirmed == 0 && !this.OCP) || this.checkedOrders.includes(element.id)) continue;
        try {
          await this.$store.dispatch('confirmTransaction', {
            id: element.id,
            sku: element.sku,
            qty_confirmed: element.qty_confirmed,
            supplier: supplier1,
          });
          this.orders_id.push(element.id);
          final_array.push(element.id);
          if (element.qty_request > element.qty_confirmed) {
            let obj = {
              id: element.id,
              sku: element.sku,
              nome: element.name,
              diff: Number(element.qty_request) - Number(element.qty_confirmed),
            };
            this.miss_prod.push(obj);
          }
        } catch (err) {
          alert(err);
        }
      }
      const id_supp = this.suppliers.filter((supp) => supp.name == supplier1);
      try {
        await this.$store.dispatch('createInvoiceTrans', {
          id: final_array,
          supplier: id_supp[0].id,
          generalDiscount: 0,
          valueExtra: 0,
          paymentMethod: 'Transferência Bancária',
        });
        final_array = [];
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
      } finally {
        final_array = [];
        await this.farmaOrders(true);
      }
      if (this.miss_prod.length != 0) {
        console.log('MISS MISS MISS');
        console.log(this.miss_prod);
        try {
          await this.$store.dispatch('sendEmailMiss', {
            obj: this.miss_prod,
          });
        } catch (err) {
          this.$swal({
            icon: 'error',
            title: 'Ooops',
            text: err,
          });
        } finally {
          this.miss_prod = [];
        }
        this.miss_prod = [];
      }
      // location.reload();
    },
    async confirmLabsRequest(orders, lab) {
      this.$swal({
        title: 'Confirmar Envio à Idivia?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, confirmar!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let supplier1 = JSON.parse(localStorage.getItem('user')).username.toUpperCase();
          console.log(orders.length);
          console.log(lab);
          for (let index = 0; index < orders.length; index++) {
            const element = orders[index];
            console.log(element);
            if (element.qty_confirmed > 0) {
              try {
                await this.$store.dispatch('confirmTransaction', {
                  id: element.id_trans,
                  sku: element.sku,
                  qty_confirmed: element.qty_confirmed,
                  supplier: lab,
                  toLab: true,
                });
                this.orders_id.push(element.id_trans);
              } catch (err) {
                alert(err);
              }
            }
          }
          console.log(this.orders_id);
          try {
            const id_supp = this.suppliers.filter((supp) => supp.name == supplier1);
            await this.$store.dispatch('createInvoiceTrans', {
              id: this.orders_id,
              supplier: id_supp[0].id,
              generalDiscount: 0,
              valueExtra: 0,
              paymentMethod: 'Transferência Bancária',
            });
            this.orders_id = [];
            this.$swal({
              toast: true,
              position: 'top-end',
              title: 'Encomenda enviada à Idivia!',
              icon: 'success',
              timer: 1500,
            });
          } catch (err) {
            this.$swal({
              icon: 'error',
              title: 'Ooops',
              text: err,
            });
          } finally {
            this.farmasLab = this.farmasLab.map((item) => ({
              ...item,
              orders: item.orders.filter((order) => order.qty_confirmed == 0),
            }));
            this.orders = [];
            this.orders_id = [];
          }
        }
      });
    },
    async cancelModal(id) {
      this.$swal({
        title: 'Tem a certeza?',
        text: 'Esta ação não pode ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ff9393',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, cancelar!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('cancelTransaction', {
              id: id,
            });
            this.$swal({
              toast: true,
              position: 'top-end',
              title: 'Transação cancelada!',
              icon: 'success',
              timer: 1500,
            });
          } catch (err) {
            this.$swal({
              icon: 'error',
              title: 'Ooops',
              text: err,
            });
          } finally {
            if (this.lastButtonClicked === 'farmaOrders') {
              this.farmaOrders(false);
            } else if (this.lastButtonClicked === 'farmaOrdersOCP') {
              this.farmaOrdersOCP();
            }
          }
        }
      });
    },
    async cancelTransactions() {
      try {
        await this.$store.dispatch('cancelFarmasTransactions', {
          ids: this.checkedOrders,
        });
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
      }
    },
    modalUpdate(id, name, sku, order, confirmed) {
      console.log(id, name, sku, order, confirmed);
      this.modal_id = id;
      this.modal_name = name;
      this.modal_sku = sku;
      this.modal_qty_order = order;
      this.modal_qty_confirmed = confirmed;
    },
    async UpdateTransaction() {
      try {
        await this.$store.dispatch('updateTransaction', {
          id: this.modal_id,
          sku: this.modal_sku,
          qty_confirmed: this.modal_qty_confirmed,
        });
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
      }
      this.$swal({
        icon: 'success',
        title: 'Sucesso',
        text: 'Transação atualizada com sucesso!',
      });
      this.$refs['modal_externSupplier'].hide();
      this.farmaOrdersConf();
    },
    async getAllProducts() {
      /* this.loading = true; */
      this.user = JSON.parse(localStorage.getItem('user'));
      if (this.user.username == 'Farmacia Alvim') {
        this.show_OCP = true;
      }
      try {
        await this.$store.dispatch('fetchProducts');
        this.products = this.getProducts.data;
      } catch (err) {
        alert(err);
      } /* finally {
        this.loading = false;
      } */
    },
    async getEAN() {
      try {
        await this.$store.dispatch('fetchProductsEAN');
        this.eans = this.getProductsEAN;
        console.log(this.eans);
      } catch (err) {
        alert(err);
      }
    },
    async passProductData(data) {
      console.log(data);
      const filterProduct = this.products.filter((product) => product.sku.includes(data.sku));
      this.eanProduct = filterProduct[0].ean;
      this.skuProduct = filterProduct[0].sku;
      this.hsProduct = filterProduct[0].hscode;
      this.warehouseProduct = filterProduct[0].note_warehouse;
      this.notesProduct = filterProduct[0].note_finish;
      this.weightProduct = filterProduct[0].weight;
      this.imgProduct = filterProduct[0].img;
      this.idProduct = filterProduct[0].id_backoffice;
      this.stockProduct = filterProduct[0].stock;
      console.log(filterProduct, data);
    },
    async getAllSuppliers() {
      try {
        await this.$store.dispatch('getAllSuppliers');
        console.log(this.getSuppliers);
        let obj;
        for (let index = 0; index < this.getSuppliers.length; index++) {
          const element = this.getSuppliers[index];
          obj = {
            id: element.id,
            name: element.name,
          };
          this.suppliers.push(obj);
        }
      } catch (error) {
        alert(error);
      }
    },
    async farmasPurchaseLab() {
      this.farmasLabs = true;
      document.getElementById('processing1').style.backgroundColor = 'gray';
      if (this.show_OCP) document.getElementById('processing2').style.backgroundColor = 'gray';
      document.getElementById('processing4').style.backgroundColor = 'blue';
      document.getElementById('processing3').style.backgroundColor = 'gray';
      try {
        await this.$store.dispatch('farmasLabPurchase');
        this.farmasLab = this.getfarmasPurchaseLab;
        console.log(this.farmasPurchaseLab);
      } catch (err) {
        alert(err);
      }
    },
  },
  async created() {
    this.loading = true;
    await this.getAllProducts();
    await this.farmaOrders(true);
    await this.getAllSuppliers();
    // await this.getEAN();
    this.loading = false;
  },
  computed: {
    ...mapGetters(['getFarmaOrders']),
    ...mapGetters(['getFarmaOrdersConf']),
    ...mapGetters(['getFarmaOrderId']),
    ...mapGetters(['getProductsEAN']),
    ...mapGetters(['getProducts']),
    ...mapGetters(['getfarmasPurchaseLab']),
    ...mapGetters(['getSuppliers']),
  },
};
</script>

<style scoped>
.table {
  text-align: center;
}
.center {
  text-align: center;
}
.processing_first {
  margin: 30px 5px 0px 5px;
  background-color: blue;
  border-color: gray;
}
.processing {
  margin: 30px 5px 0px 5px;
  background-color: gray;
  border-color: gray;
}
button:visited {
  background-color: #ff9393;
}
.completed {
  margin: 30px 5px 0px 5px;
  background-color: green;
  border-color: green;
}
div .col {
  padding: 0;
}
.form-control {
  text-align: center;
}
.form-control1 {
  text-align: center;
  max-width: 200px;
}
.box img {
  width: 100%;
  height: 200px;
}
.box1 img {
  object-fit: contain;
}
.box1 {
  border: 3px solid #ff9393;
  border-radius: 5px;
}
.column {
  float: left;
  width: 50%;
  padding: 5px;
}
#searchEan {
  margin-top: 50px;
}
#lblProductEan {
  text-align: right;
}
/* table {
  table-layout: fixed;
} */
/* h5 {
  text-align: right;
  margin-right: 7%;
} */
.buttons {
  text-align: right;
}
.buttons button {
  margin: 5px;
}
img {
  max-width: 100%;
  border-radius: 18px;
}

#openModal {
  background-color: blue;
}

#openModal:hover {
  background-color: white;
  color: blue;
}

#cardProduct {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 10px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#subtitle {
  color: white;
  font-weight: bold;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
.card {
  margin: 15px;
}
.large-checkbox {
  width: 20px;
  height: 20px;
}
</style>
